

































import { Component, Vue } from "vue-property-decorator";
import { posts } from "@/data/posts";
import LazyImage from "@/components/LazyImage.vue";
import strftime from "strftime";
@Component({ components: { LazyImage } })
export default class Home extends Vue {
  blogPosts = posts.filter((p) => p.departmentIDs.includes("blog")).slice(0, 8);

  formatDate(d: Date): string {
    return strftime("%D", d);
  }
}
